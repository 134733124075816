<template>
	<GridVideo
		v-bind="composedProps"
		@dragstart.stop.prevent
	/>
</template>

<script>
import GridVideo from '@user/components/grid-components/video/GridVideo.vue';
import { useGridVideo } from '@user/components/grid-components/video/useGridVideo';

export default {
	name: 'GridVideoProviderBuilder',
	components: { GridVideo },
	props: {
		data: {
			type: Object,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
	},
	setup(props) {
		const { composedProps } = useGridVideo(props);

		return { composedProps };
	},
};
</script>
